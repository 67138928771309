import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import './SensorDataComponent.css';  // Подключим файл CSS для стилей
import { Spinner } from 'react-bootstrap';

function SensorDataComponent() {
  const [deviceIds, setDeviceIds] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [sensorData, setSensorData] = useState([]);
  const [last24HoursData, setLast24HoursData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [newDeviceName, setNewDeviceName] = useState(''); // Новое состояние для нового имени устройства
  const [isLoading, setIsLoading] = useState(false); // Состояние для отображения индикатора загрузки

  // Fetch list of device IDs
  const fetchDeviceIds = async () => {
    try {
      const response = await axios.get('https://plantation-f09b2b81370c.herokuapp.com/api/core-ids');
      console.log('Device IDs response:', response.data);
      setDeviceIds(response.data); // response.data должно быть массивом объектов
    } catch (error) {
      console.error('Failed to fetch device IDs:', error);
    }
  };

  useEffect(() => {
    fetchDeviceIds();
  }, []);

  // Fetch sensor data for the selected device ID (latest)
  useEffect(() => {
    if (selectedDeviceId) {
      async function fetchSensorData() {
        try {
          const response = await axios.get(`https://plantation-f09b2b81370c.herokuapp.com/api/sensors/latest`, {
            params: {
              deviceId: selectedDeviceId,
              page: currentPage,
              size: 10
            }
          });
          console.log('Sensor data response:', response.data);
          setSensorData(response.data.data); // Assuming response.data.data contains the list of sensor data
          setTotalPages(response.data.totalPages); // Set the total number of pages
        } catch (error) {
          console.error('Error fetching sensor data:', error);
        }
      }
      fetchSensorData();
    }
  }, [selectedDeviceId, currentPage]);

  // Fetch sensor data for the selected device ID (last 24 hours)
  useEffect(() => {
    if (selectedDeviceId) {
      async function fetchLast24HoursData() {
        try {
          const response = await axios.get(`https://plantation-f09b2b81370c.herokuapp.com/api/sensorData/last24Hours/${selectedDeviceId}`);
          console.log('Last 24 hours data response:', response.data);
          setLast24HoursData(response.data);
        } catch (error) {
          console.error('Error fetching last 24 hours sensor data:', error);
        }
      }
      fetchLast24HoursData();
    }
  }, [selectedDeviceId]);

  // Function to convert timestamp to formatted string
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem', hour: '2-digit', minute: '2-digit' });
  }

  // Function to generate hourly timestamps for the last 24 hours
  const generateHourlyTimestamps = () => {
    const now = new Date();
    const timestamps = [];
    for (let i = 0; i < 24; i++) {
      const date = new Date(now.getTime() - i * 60 * 60 * 1000);
      timestamps.unshift(date);
    }
    return timestamps;
  };

  const hourlyTimestamps = generateHourlyTimestamps();

  // Function to map last 24 hours sensor data to hourly timestamps
  const mapSensorDataToHourly = (data, key) => {
    const mappedData = hourlyTimestamps.map(hour => {
      const closestDataPoint = data.reduce((prev, curr) => {
        if (!prev || !curr) return prev;
        return Math.abs(curr.timestamp * 1000 - hour.getTime()) < Math.abs(prev.timestamp * 1000 - hour.getTime()) ? curr : prev;
      }, data[0]);
      return closestDataPoint ? {
        timestamp: hour.getTime() / 1000,
        [key]: closestDataPoint[key]
      } : {
        timestamp: hour.getTime() / 1000,
        [key]: null
      };
    });

    // Добавляем промежуточные данные
    data.forEach(dataPoint => {
      if (dataPoint) {
        mappedData.push({
          timestamp: dataPoint.timestamp,
          [key]: dataPoint[key]
        });
      }
    });

    // Сортируем данные по времени
    mappedData.sort((a, b) => a.timestamp - b.timestamp);

    return mappedData;
  };

  const mappedTemperatureData = mapSensorDataToHourly(last24HoursData, 'temperature');
  const mappedHumidityData = mapSensorDataToHourly(last24HoursData, 'humidity');

  // Function to handle name change
  const handleNameChange = async () => {
    if (selectedDeviceId && newDeviceName) {
      setIsLoading(true); // Включаем индикатор загрузки
      try {
        const response = await axios.post(`https://plantation-f09b2b81370c.herokuapp.com/api/device/update-name`, {
          deviceId: selectedDeviceId,
          newDeviceName: newDeviceName
        });
        console.log('Name change response:', response.data);
        setNewDeviceName(''); // Очистить поле ввода после успешного обновления
        await fetchDeviceIds(); // Обновляем список устройств
        setSelectedDeviceId(''); // Сбросить выбранное устройство
      } catch (error) {
        console.error('Error updating device name:', error);
      } finally {
        setIsLoading(false); // Отключаем индикатор загрузки
      }
    }
  };

  return (
    <div className="sensor-data-component">
      <select onChange={e => setSelectedDeviceId(e.target.value)} value={selectedDeviceId} className="device-select">
        <option value="">Select Device ID</option>
        {deviceIds.map(id => (
          <option key={id.deviceId} value={id.deviceId}>
            {id.deviceName || "Unnamed Device"} ({id.deviceId})
          </option>
        ))}
      </select>

      <div className="name-change">
        <input
          type="text"
          value={newDeviceName}
          onChange={e => setNewDeviceName(e.target.value)}
          placeholder="Enter new device name"
          className="name-input"
        />
        <button onClick={handleNameChange} className="name-change-button" disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : "Change Name"}
        </button>
      </div>

      <table className="sensor-table">
        <thead>
          <tr>
            <th>Date and Time</th>
            <th>Temperature (°C)</th>
            <th>Humidity (%)</th>
          </tr>
        </thead>
        <tbody>
          {sensorData.map(item => (
            <tr key={item.timestamp}>
              <td>{formatTimestamp(item.timestamp)}</td>
              <td>{item.temperature.toFixed(1)}</td>
              <td>{item.humidity.toFixed(1)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => setCurrentPage(p => Math.max(p - 1, 0))} disabled={currentPage === 0} className="pagination-button">Previous</button>
        <button onClick={() => setCurrentPage(p => Math.min(p + 1, totalPages - 1))} disabled={currentPage >= totalPages - 1} className="pagination-button">Next</button>
      </div>

      <h2>Temperature Data</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={mappedTemperatureData}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={timestamp => new Date(timestamp * 1000).toLocaleTimeString([], { hour: '2-digit' })} 
            label={{ value: 'Time', position: 'insideBottomRight', offset: -5 }}
            ticks={hourlyTimestamps.map(ts => ts.getTime() / 1000)}
          />
          <YAxis 
            label={{ value: 'Temperature (°C)', angle: -90, position: 'insideLeft' }} 
            domain={[15, 40]}
          />
          <Tooltip 
            labelFormatter={label => new Date(label * 1000).toLocaleString()} 
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="temperature" 
            stroke="#8884d8" 
            activeDot={{ r: 8 }} 
          />
        </LineChart>
      </ResponsiveContainer>

      <h2>Humidity Data</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={mappedHumidityData}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={timestamp => new Date(timestamp * 1000).toLocaleTimeString([], { hour: '2-digit' })} 
            label={{ value: 'Time', position: 'insideBottomRight', offset: -5 }}
            ticks={hourlyTimestamps.map(ts => ts.getTime() / 1000)}
          />
          <YAxis 
            label={{ value: 'Humidity (%)', angle: -90, position: 'insideLeft' }} 
            domain={[40, 100]}
          />
          <Tooltip 
            labelFormatter={label => new Date(label * 1000).toLocaleString()} 
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="humidity" 
            stroke="#82ca9d" 
            activeDot={{ r: 8 }} 
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SensorDataComponent;
