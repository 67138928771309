// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sensor-data-component {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .device-select {
    margin-bottom: 20px;
    padding: 8px;
    font-size: 16px;
  }
  
  .sensor-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .sensor-table th, .sensor-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .sensor-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .sensor-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .sensor-table tr:hover {
    background-color: #ddd;
  }
  
  .pagination {
    margin-bottom: 20px;
  }
  
  .pagination-button {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  `, "",{"version":3,"sources":["webpack://./src/SensorDataComponent.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd","sourcesContent":[".sensor-data-component {\n    font-family: Arial, sans-serif;\n    margin: 20px;\n  }\n  \n  .device-select {\n    margin-bottom: 20px;\n    padding: 8px;\n    font-size: 16px;\n  }\n  \n  .sensor-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n  }\n  \n  .sensor-table th, .sensor-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .sensor-table th {\n    background-color: #f2f2f2;\n    text-align: left;\n  }\n  \n  .sensor-table tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .sensor-table tr:hover {\n    background-color: #ddd;\n  }\n  \n  .pagination {\n    margin-bottom: 20px;\n  }\n  \n  .pagination-button {\n    padding: 10px 20px;\n    margin: 5px;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  \n  .pagination-button:disabled {\n    cursor: not-allowed;\n    opacity: 0.5;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
