import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Navbar, Nav, Container } from 'react-bootstrap';
import SensorDataComponent from './SensorDataComponent'; 

function App() {
  return (
    <Router>
      <Container className="p-3">
        <NavbarComponent />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/sensor-data" element={<SensorDataComponent />} /> 
        </Routes>
      </Container>
    </Router>
  );
}

function NavbarComponent() {
  return (
    <>
      <Navbar bg="light" expand="lg" className="justify-content-center">
        <Nav>
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/sensor-data">Sensor Data</Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}

function HomeComponent() {
  return (
    <div className="App">
      <Container>
        <h1 className="text-center my-4">Welcome to Shefa Green!</h1>
        <p>
          At Shefa Green, we are at the forefront of cultivating medical cannabis 
          through cutting-edge technology. Our commitment to excellence and innovation 
          shapes our approach, as we harness the power of the latest advancements 
          to nurture our plants.
        </p>
        <p>
          Embracing a fully remote-controlled process, we ensure precision and 
          efficiency at every stage. Our state-of-the-art facilities are designed 
          to optimize the growth conditions, ensuring that each plant receives 
          the perfect balance of light, nutrients, and care.
        </p>
        <p>
          Our mission is to deliver the highest quality of medical cannabis, 
          responsibly and sustainably produced. We believe in the power of nature, 
          enhanced by science, to bring the best therapeutic experiences to our clients.
        </p>
        <p>
          Join us in exploring the future of cannabis cultivation, where technology 
          meets nature in harmony.
        </p>
      </Container>
    </div>
  );
}

export default App;
